<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="crmNotifications"
                          base-table="customer"
                          columnsPrefix="common.column.crm_notification."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.crm_notifications"
                >
                    <template #cell(sent)="row">
                        <CBadge :color="row.item.sent ? 'success' : 'danger' ">{{
                                row.item.sent ? $t('common.button.yes')
                                    :
                                    $t('common.button.no')
                            }}
                        </CBadge>
                    </template>

                    <template #cell(total)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="openNotification(row.item)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CrmNotifications',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Crm', ['getNotificationsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Crm/fetchNotifications', 'getNotificationsTable');
        },
        openNotification(item) {
            if (item.type == 'todos') {
                this.$root.$children[0].openModal('todo-modal', {id: item.parent_id}, this.refreshTable)
            }
            if (item.type == 'customer_reports') {
                this.$root.$children[0].openModal('customer-report-modal', {
                    id: item.parent_id,
                    customerId: item.customer_id,
                }, this.refreshTable)
            }
        }
    },
}
</script>